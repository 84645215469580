import React from "react";
import { useQuery } from "react-query";
import { getQuizResults } from "../api";
import {
    VStack,
    Heading,
    SimpleGrid,
    Text,
    GridItem,
} from "@chakra-ui/react"; import { useAuth } from "./AuthContext";
const QuizResults: React.FC = () => {
    const { isTokenExpired } = useAuth();
    isTokenExpired();
    const { isLoading, isError, data, error } = useQuery( // it's a POST
        "quizResults",
        getQuizResults,
        { refetchInterval: 3000 }
    );
    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error: {error}</span>;
    }

    const { totals } = data as Record<string, any>;
    const totalVotes = totals.reduce((acc: number, item: Record<string, any>) => { return acc + Number(item.votes) }, 0)

    return (
        <>
            <Heading size="md" textAlign="left">P2 Results:</Heading>
            <Text fontSize="lg" textAlign="left">Total Votes: {totalVotes}</Text>
            {totals.map((item: Record<string, any>) => <Text fontSize="md" textAlign="left" key={item.choice}>{item.choice}: {item.percentage}%</Text>)}
        </>
    )
};

export default QuizResults;
