import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  SimpleGrid,
  GridItem,
  Button,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import { useAuth } from "./AuthContext";
import { adminLogin } from "../api";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
const jwt = require("jsonwebtoken");

const LoginForm = () => {
  const { saveAuthInfo } = useAuth();

  const validateEmail = (value: string) => {
    let error;
    if (!value) {
      error = "Email is required";
    }
    return error;
  };

  const validatePassword = (value: string) => {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  };

  return (
    <Formik
      initialValues={{
        email: process.env.REACT_APP_LOCAL_EMAIL_DEFAULT || "",
        password: process.env.REACT_APP_LOCAL_PASSWORD_DEFAULT || "",
      }}
      onSubmit={async (values, actions) => {
        try {
          const token = await adminLogin(values.email, values.password);
          const decodedToken = jwt.verify(
            token,
            process.env.REACT_APP_SESSION_SECRET || ""
          );
          console.log("decodedToken: ", decodedToken);
          // const user: Record<string, any> = await currentUser(token);
          saveAuthInfo({
            userInfo: {
              name: decodedToken.name,
              avatar: decodedToken.avatar,
              systemRole: decodedToken.systemRole,
            },
            token,
            expiresAt: decodedToken.exp,
          });
          actions.setSubmitting(false);
        } catch (err: any) {
          alert(err.response?.errors[0].message);
        }
      }}
    >
      {(props: FormikProps<any>) => (
        <VStack w="50%" h="full" p={10} spacing={10} alignItems="flex-start">
          <VStack spacing={3} alignItems="flex-start">
            <Heading size="2xl">Login</Heading>
          </VStack>
          <Form style={{ width: "100%" }}>
            <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
              <GridItem colSpan={2}>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isInvalid={
                        form.errors.email && form.touched.email ? true : false
                      }
                    >
                      <FormLabel htmlFor="email">Email Address</FormLabel>
                      <Input {...field} id="email" placeholder="email" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem colSpan={2}>
                <Field name="password" validate={validatePassword}>
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isInvalid={
                        form.errors.password && form.touched.password
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <PasswordInput
                        {...field}
                        id="password"
                        placeholder="password"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem colSpan={2}>
                <Button
                  size="lg"
                  w="full"
                  type="submit"
                  isLoading={props.isSubmitting}
                >
                  Login
                </Button>
              </GridItem>
            </SimpleGrid>
          </Form>
        </VStack>
      )}
    </Formik>
  );
};

export default LoginForm;

const PasswordInput = (props: any) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <InputGroup size="md">
      <Input
        {...props}
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
