import React from "react";
import { useAuth } from "./AuthContext";
import DataDisplay from "./DataDisplay";
import LoginForm from "./LoginForm";
import ServerTime from "./ServerTime";
import UserBadge from "./UserBadge";
// import CubeForm from "./CubeForm";
import LowerThirdForm from "./LowerThirdForm";
import QuizForm from "./QuizForm";
import ResetForm from "./ResetForm";
import QuizResults from "./QuizResults";
import ChannelForm from "./ChannelForm";
import VersionLabel from "./VersionLabel";
import {
  VStack,
  Heading,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { useQuery } from "react-query";
import { getData } from "../api";

const Main: React.FC = () => {
  const { authInfo, isAuthenticated, logout } = useAuth();
  const authenticated = isAuthenticated();

  const { isLoading, isError, data, error } = useQuery(
    "overlayData",
    getData,
    { refetchInterval: 5000, enabled: isAuthenticated(), staleTime: 4000 }
  );

  if (authenticated && isLoading) {
    return <span>Loading...</span>;
  }

  if (authenticated && isError) {
    return <span>Error: {error}</span>;
  }

  const { name, avatar, systemRole } = authInfo?.userInfo!;

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    console.log("LOGGING OUT!");
    logout();
  };
  return (
    <>

      {authenticated ? (
        <>
          <VStack w="full" h="full" pt={2} pl={6} spacing={10} alignItems="flex-start">
            <SimpleGrid columns={3} columnGap={1} rowGap={1} w="full" alignItems="flex-start">
              <GridItem colSpan={1} >
                <VStack spacing={1} alignItems="flex-start">
                  <SimpleGrid columns={1} w="full">
                    <GridItem colSpan={1}>
                      <Heading size="lg" p={0}>CNNx Mission Control</Heading>
                      <ServerTime />
                    </GridItem>
                  </SimpleGrid>
                </VStack>
              </GridItem>
              <GridItem colSpan={1}><ColorModeSwitcher /></GridItem>
              <GridItem colSpan={1}>
                <UserBadge
                  name={name!}
                  avatar={avatar!}
                  systemRole={systemRole!}
                  handleLogout={onLogoutClick}
                />
              </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={2} columnGap={12} rowGap={6} w="full">
              <GridItem colSpan={1}>
                {/* <CubeForm data={data!} /> */}
                <LowerThirdForm data={data!} />
                <QuizForm data={data!} />
                <ChannelForm data={data!} />
              </GridItem>
              <GridItem colSpan={1}>
                <QuizResults />
                <ResetForm />
                <DataDisplay data={data!} />
              </GridItem>
            </SimpleGrid>

          </VStack>
          <SimpleGrid columns={1} pl={2} w="full">
            <VersionLabel />
          </SimpleGrid>
        </>
      ) : (
        <LoginForm />
      )}
    </>
  );
};

export default Main;
