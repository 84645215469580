import React from "react";
import { Flex, Avatar, Box, Text, Button } from "@chakra-ui/react";

const UserBadge: React.FC<{
  name: string;
  avatar: string;
  systemRole: string;
  handleLogout: (e: any) => void;
}> = ({ avatar, name, systemRole, handleLogout }) => {
  return (
    <Flex>
      <Avatar name={name} src={avatar} />
      <Box ml="3">
        <Text fontWeight="bold">{name}</Text>
        <Text fontSize="sm" textAlign="left">
          {systemRole}
        </Text>
      </Box>
      <Box ml="18">
        <Button m={2} p={4} size="xs" onClick={handleLogout}>
          LOGOUT
        </Button>
      </Box>
    </Flex>
  );
};

export default UserBadge;
