import {
  FormControl,
  VStack,
  SimpleGrid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { SelectControl, SubmitButton } from "formik-chakra-ui";
import React from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { updateData, UpdateParams } from "../api";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "./AuthContext";
import { sendID3Action } from "../api";

const QuizForm: React.FC<{ data: Record<string, any> }> = ({
  children,
  data,
}) => {
  const { currentQuestionID, channelID } = data;
  const { authInfo, isTokenExpired } = useAuth();

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    ({ filename, data, token }: UpdateParams) => {
      return updateData({ filename, data, token });
    },
    {
      onSuccess: async (response, variables) => {
        const { resetForm } = variables;
        queryClient.setQueryData(["overlayData"], response.body.updated);
        resetForm!({
          values: { question: response.body.updated.currentQuestionID },
        });
        const question = data.questions.find((q: Record<string, any>) => q.id === response.body.updated.currentQuestionID);
        try {
          await sendID3Action({
            channelID,
            actionName: "Quiz",
            type: "Quiz",
            action: 'Show',
            id: question?.id,
            question: question?.body,
            choices: question?.choices,
            tally: question?.tally,
            dryrun: true
          })
        } catch (err) {
          console.log('error sending id3 action: ', err)
        }
      },
    }
  );

  return (
    <Formik
      initialValues={{ question: currentQuestionID }}
      validateOnChange
      onSubmit={(values, actions) => {
        console.log("VALUES: ", values)
        actions.setSubmitting(true);
        try {
          const expired = isTokenExpired();
          if (!expired) {
            updateMutation.mutate({
              filename: process.env.REACT_APP_DATA_FILENAME!,
              data: [
                {
                  name: "currentQuestionID",
                  path: "currentQuestionID",
                  value: values.question,
                }
              ],
              token: authInfo.token!,
              resetForm: actions.resetForm,
            });
          } else {
            console.log("expired!");
          }
        } catch (err: any) {
          console.log(err);
        }
      }}
    >
      {(props: FormikProps<any>) => (
        <VStack w="100%" p={0} spacing={10} alignItems="flex-start">
          <Form style={{ width: "100%" }}>
            <SimpleGrid columns={2} rowGap={1} w="full" mt={4}>
              <GridItem colSpan={2}>
                <Text mt={2} align="left" fontSize="md">Current Question:</Text>
              </GridItem>
              <GridItem colSpan={1} mt={2}>
                <Field name="question">
                  {({ field, form }: FieldProps) => (
                    <FormControl>
                      <SelectControl
                        name="question"
                        selectProps={{ isFullWidth: false, size: 'sm' }}>
                        {data.questions.map((question: any) => (
                          <option key={question.id} value={question.id} label={question.title} >
                            {question.title}
                          </option>
                        ))}
                      </SelectControl>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem colSpan={1}>
                <Field name="submit">
                  {({ field, form }: FieldProps) => (
                    <>
                      <SubmitButton
                        size="sm"
                        w="30%"
                        mt={2}
                        color={"#cccccc"}
                        backgroundColor={"#233ea1"}
                        _hover={{
                          color: form.dirty ? "white" : "#cccccc",
                          backgroundColor: form.dirty && !props.isSubmitting ? "#172863" : "#233ea1",
                        }}
                        isDisabled={!form.dirty || props.isSubmitting}
                        type="submit"
                        isLoading={props.isSubmitting}
                      >
                        Update
                      </SubmitButton>
                    </>
                  )}
                </Field>
              </GridItem>
            </SimpleGrid>
          </Form>
        </VStack >
      )
      }
    </Formik >
  );
};

export default QuizForm;
