import { GraphQLClient, gql } from "graphql-request";
import axios, { AxiosResponse, AxiosError } from "axios";

let client: GraphQLClient;
const getClient = async (): Promise<GraphQLClient> => {
  const graphqlEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;
  console.log("graphql endpoint: ", graphqlEndpoint);
  if (!client && graphqlEndpoint) {
    client = new GraphQLClient(graphqlEndpoint);
  }
  return client;
};

const AdminLogin = gql`
  mutation AdminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      token
    }
  }
`;

export const adminLogin = async (
  email: string,
  password: string
): Promise<string> => {
  const client = await getClient();
  const response = await client.request(AdminLogin, { email, password });
  const token = response.adminLogin?.token;
  return token;
};

export const getQuizResults = async (): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  try {
    response = await axios.get(`${process.env.REACT_APP_QUIZ_RESULTS_ENDPOINT}?quizID=CNNx`!, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error getting quiz results: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> getQuizResults", response?.data);
    return err as AxiosError;
  }
} 

export const getServerTime = async (): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  try {
    response = await axios.get(process.env.REACT_APP_TIME_SERVICE_ENDPOINT!, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error getting server time: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> getServerTime", response?.data);
    return err as AxiosError;
  }
};

export const getData = async (): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  const endpoint = `${process.env.REACT_APP_DATA_PATH}/${
    process.env.REACT_APP_DATA_FILENAME
  }?t=${Math.floor(new Date().getTime() / 1000)}`!;
  try {
    response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error getting data: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> fetch data", response?.data);
    return err as AxiosError;
  }
};

export type UpdateParams = {
  filename: string;
  data: Record<string, any>[];
  token: string;
  dryrun?: boolean;
  resetForm?: (nextState: any) => void;
};

export const updateData = async ({
  filename,
  data,
  token,
}: UpdateParams): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  try {
    response = await axios.post(
      process.env.REACT_APP_DATA_UPDATE_ENDPOINT!,
      { filename, data, dryrun: false },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("axios response: ", response);
    if (response.data.errors) {
      throw new Error(
        `error getting server time: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    console.error("-----> updateData", err);
    return err as AxiosError;
  }
};

export type ID3ActionParams = {
  channelID: string;
  actionName: string;
  type: 'Cube' | 'LowerThird' | 'Notification' | 'Quiz';
  action: string;
  id?: string;
  question?: string;
  choices?: string[];
  tally?: boolean;
  dryrun?: boolean;
}

export const sendID3Action = async ({
  channelID,
  actionName,
  type, 
  action,
  id,
  question, 
  choices, 
  tally,
  dryrun = false
}:ID3ActionParams): Promise<Record<string, any>> => {
  let params = {
    questionID: id,
    channelID, 
    actionName: `${actionName}_${Math.floor(new Date().getTime() / 1000)}`, 
    meta: {
      type, 
      action,
      id,
      // question,
      // choices,
      tally
    }
  }
  console.log("sending id3 action: ", params);
  let response: AxiosResponse;
  const endpoint = process.env.REACT_APP_ID3ACTION_ENDPOINT || "";
  try {
    response = await axios.post(endpoint, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error sending id3 action: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> id3Action", response?.data);
    return err as AxiosError;
  }
}

export const resetVotes = async ({
 quizID,
 token
}:{
  quizID: string,
  token: string
}): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  console.log(`RESETTING VOTES for quiz ID ${quizID} `)
  try {
    response = await axios.post(
      process.env.REACT_APP_RESET_VOTES_ENDPOINT!,
      { quizID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("axios response: ", response);
    if (response.data.errors) {
      throw new Error(
        `error resetting votes: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    console.error("-----> resetVotes", err);
    return err as AxiosError;
  }
}

export type ResetVotesParams = {
  quizID: string;
  token: string;
}