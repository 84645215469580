import { VStack } from "@chakra-ui/react";
import { SubmitButton } from "formik-chakra-ui";
import React, { Ref } from "react";
import Dialog from "./Dialog";
import { useAuth } from "./AuthContext";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { resetVotes, ResetVotesParams } from "../api";
import { useQueryClient, useMutation } from "react-query";

const ResetForm: React.FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const cancelRef: Ref<any> = React.useRef();

  const { authInfo } = useAuth();

  const queryClient = useQueryClient();

  const resetMutation = useMutation(
    ({ quizID, token }: ResetVotesParams) => {
      return resetVotes({ quizID, token });
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["houseData"], response.body.updated);
      },
    }
  );

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };
  const handleDialogAction = async () => {
    try {
      resetMutation.mutate({
        quizID: "CNNx",
        token: authInfo.token!,
      });
      setDialogIsOpen(false);
    } catch (err: any) {
      console.log(err);
    }
  };
  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          try {
            setDialogIsOpen(true);
          } catch (err: any) {
            console.log(err);
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <VStack w="30%" pt={4} spacing={10}  >
            <Form style={{ width: "100%" }}>
              <Field name="reset">
                {({ field, form }: FieldProps) => (
                  <SubmitButton
                    size="md"
                    w="full"
                    color={"lightgray"}
                    backgroundColor={"#444444"}
                    _hover={{
                      color: "white",
                      backgroundColor: "darkred",
                    }}
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    Reset Votes
                  </SubmitButton>
                )}
              </Field>
            </Form>
          </VStack>
        )}
      </Formik>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        cancelRef={cancelRef}
        onClose={handleDialogClose}
        onAction={handleDialogAction}
        title="Reset Votes"
        body="Are you sure you want to reset the votes?"
        cancelLabel="Cancel"
        actionLabel="Reset"
      />
    </>
  );
};

export default ResetForm;
