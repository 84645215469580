import React, { RefObject } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
} from "@chakra-ui/react";

const Dialog = ({
  dialogIsOpen,
  cancelRef,
  onClose,
  onAction,
  title,
  body,
  cancelLabel,
  actionLabel,
}: {
  dialogIsOpen: boolean;
  cancelRef: RefObject<HTMLButtonElement>;
  onClose: () => void;
  onAction: () => void;
  title: string;
  body: string;
  cancelLabel: string;
  actionLabel: string;
}) => {
  return (
    <>
      <AlertDialog
        isOpen={dialogIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelLabel}
              </Button>
              <Button colorScheme="red" onClick={onAction} ml={3}>
                {actionLabel}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Dialog;
