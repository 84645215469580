import React from "react";
import { Box, Text } from "@chakra-ui/react";

const VersionLabel: React.FC = () => {
  return (
    <Box pt={6}>
      <Text fontSize="sm" textAlign="left">
        commit: {process.env.REACT_APP_COMMIT_ID}
      </Text>
    </Box>
  );
};

export default VersionLabel;
