import React from "react";
import { ChakraProvider, Box, VStack, Grid, theme } from "@chakra-ui/react";
import Main from "./components/Main";
import { AuthProvider } from "./components/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Box textAlign="center" fontSize="xl">
            <Grid minH="100vh" p={3}>
              <VStack spacing={1}>
                <Main />
              </VStack>
            </Grid>
          </Box>
        </ChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};
