import React from "react";
import { useQuery } from "react-query";
import { getServerTime } from "../api";
import { format, utcToZonedTime } from "date-fns-tz";
import { useAuth } from "./AuthContext";
const ServerTime: React.FC = () => {
  const { isTokenExpired } = useAuth();
  isTokenExpired();
  const { isLoading, isError, data, error } = useQuery(
    "serverTime",
    getServerTime,
    { refetchInterval: 3000 }
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error}</span>;
  }

  const { iso } = data as Record<string, any>;
  const gmtDate = utcToZonedTime(new Date(iso), "etc/GMT");
  const formattedGMTTime = format(gmtDate, "E, MMM d — kk:mm:ss 'GMT'");

  return <h1>{formattedGMTTime}</h1>;
};

export default ServerTime;
