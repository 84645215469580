import {
  FormControl,
  VStack,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { SubmitButton, InputControl } from "formik-chakra-ui";
import React from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { updateData, UpdateParams } from "../api";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "./AuthContext";

const ChannelForm: React.FC<{ data: Record<string, any> }> = ({
  children,
  data,
}) => {
  const { channelID } = data;
  const { authInfo, isTokenExpired } = useAuth();

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    ({ filename, data, token }: UpdateParams) => {
      return updateData({ filename, data, token });
    },
    {
      onSuccess: async (response, variables) => {
        const { resetForm } = variables;
        queryClient.setQueryData(["overlayData"], response.body.updated);
        resetForm!({
          values: { visible: response.body.updated.cubeVisible },
        });
      },
    }
  );

  return (
    <Formik
      initialValues={{ channelID: channelID }}
      validateOnChange
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        try {
          const expired = isTokenExpired();
          if (!expired) {
            updateMutation.mutate({
              filename: process.env.REACT_APP_DATA_FILENAME!,
              data: [
                {
                  name: "channelID",
                  path: "channelID",
                  value: values.channelID,
                },
              ],
              token: authInfo.token!,
              resetForm: actions.resetForm,
            });
          } else {
            console.log("expired!");
          }
        } catch (err: any) {
          console.log(err);
        }
      }}
    >
      {(props: FormikProps<any>) => (
        <VStack w="100%" p={0} spacing={10} alignItems="flex-start">
          <Form style={{ width: "100%" }}>
            <SimpleGrid columns={2} rowGap={1} w="full" mt={4}>
              <GridItem colSpan={1} mt={2}>
                <Field name="notification">
                  {({ field, form }: FieldProps) => (
                    <FormControl>
                      <InputControl name="channelID" label="Channel ID:" >123</InputControl>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem colSpan={1} mt={10}>
                <Field name="submit">
                  {({ field, form }: FieldProps) => (
                    <>
                      <SubmitButton
                        size="sm"
                        w="30%"
                        color={"#cccccc"}
                        backgroundColor={"#233ea1"}
                        _hover={{
                          color: form.dirty ? "white" : "#cccccc",
                          backgroundColor: form.dirty && !props.isSubmitting ? "#172863" : "#233ea1",
                        }}
                        isDisabled={!form.dirty || props.isSubmitting}
                        type="submit"
                        isLoading={props.isSubmitting}
                      >
                        Update
                      </SubmitButton>
                    </>
                  )}
                </Field>
              </GridItem>
            </SimpleGrid>
          </Form>
        </VStack>
      )}
    </Formik>
  );
};

export default ChannelForm;
